import styles from './profile-summary.module.scss';

export type ProfileSummaryProps = {
  companyName?: string;
  content?: {
    greeting?: string;
    mdrtId?: Nullable<string>;
    membershipYears?: Nullable<string>;
  };
  designations: string[];
  location?: string;
  profileName: Nullable<string>;
};

export const ProfileSummary = ({
  companyName,
  content,
  designations,
  location,
  profileName,
}: ProfileSummaryProps) => {
  return (
    <div className={styles['container']} data-testid="profile-summary-container">
      {content?.greeting && <div className={styles.welcome}>{content?.greeting}</div>}
      <div className={styles['full-name']} data-testid="profile-summary-full-name">
        {profileName}
      </div>
      {designations.length > 0 && (
        <div className={styles.designations} data-testid="profile-summary-designations">
          {designations?.join(', ')}
        </div>
      )}
      {companyName && <div className={styles['company-name']}>{companyName}</div>}
      {(content?.membershipYears || content?.mdrtId) && (
        <div className={styles['info-row']}>
          {content.membershipYears && (
            <>
              <span data-testid="profile-summary-membership-year">{content.membershipYears}</span>
              <div className={styles['separator']}>
                <div className={styles['separator__line']} />
              </div>
            </>
          )}
          <span data-testid="profile-summary-mdrt-id">{content.mdrtId}</span>
        </div>
      )}
      {location && <div className={styles['location']}>{location}</div>}
    </div>
  );
};

'use client';

import { type ComponentType, type ReactNode, useState } from 'react';

import {
  type ProfilePhotoContentType,
  type CustomDialogProps,
  ModalTrigger,
  ProfilePhotoWithBadge,
} from '@shared/ui-components';
import { type ServerActionResponse } from '@shared/utils';

import { ProfilePhotoDialog } from './profile-photo-dialog';
import { type ProfilePhotoDialogContentType } from './profile-photo-dialog-content';
import styles from './profile-photo-container.module.scss';

export type ProfilePhotoContainerContent = {
  add: string;
  change: string;
  close: string;
  edit: string;
  fileServerError: string;
  fileSizeError: string | undefined | null;
  fileTypeError: string | undefined | null;
  modalTitle: string;
  remove: string;
  save: string;
  upload: string;
} & ProfilePhotoDialogContentType &
  ProfilePhotoContentType;

export type PhotoUploadResponse = Response | ServerActionResponse | null;

export type ProfilePhotoContainerProps = {
  abortOngoingRequests: () => void;
  badge?: ReactNode;
  cmsContent: ProfilePhotoContainerContent;
  dialogComponent: ComponentType<CustomDialogProps>;
  handleProfilePictureDelete: () => Promise<ServerActionResponse>;
  handleSubmitProfilePicture: (formData: BodyInit) => Promise<PhotoUploadResponse>;
  initialProfilePicture: string | null;
};

export const ProfilePhotoContainer = ({
  abortOngoingRequests,
  badge,
  cmsContent,
  dialogComponent,
  initialProfilePicture,
  ...restProfilePhotoDialogProps
}: ProfilePhotoContainerProps) => {
  const [profilePictureInSection, setProfilePictureInSection] = useState(
    initialProfilePicture ?? undefined
  );

  return (
    <div className={styles['profile-photo-container']} data-testid="profile-photo-container">
      <ModalTrigger
        onOpenChange={(isOpen: boolean) => {
          if (!isOpen) {
            abortOngoingRequests();
          }
        }}
        renderTrigger={({ handleModalOpen, ...restTriggerProps }) => (
          <button
            {...restTriggerProps}
            className={styles['profile-photo-container__button']}
            data-testid="profile-photo-container-button"
            onClick={() => {
              handleModalOpen();
            }}
            type="button"
          >
            <ProfilePhotoWithBadge
              actionLabelText={profilePictureInSection ? cmsContent.edit : cmsContent.upload}
              badge={badge}
              cmsContent={{
                pictureDescription: cmsContent.pictureDescription,
              }}
              hasHoverOverlay={Boolean(profilePictureInSection)}
              imageSrc={profilePictureInSection}
            />
          </button>
        )}
      >
        {(handleModalClose) => (
          <ProfilePhotoDialog
            DialogComponent={dialogComponent}
            cmsContent={cmsContent}
            handleModalClose={handleModalClose}
            initialProfilePicture={profilePictureInSection}
            setProfilePictureInSection={setProfilePictureInSection}
            {...restProfilePhotoDialogProps}
          />
        )}
      </ModalTrigger>
    </div>
  );
};
